<!-- =========================================================================================
    File Name: Login.vue
    Description: Login Page

========================================================================================== -->


<template>
  <div>
    <div class="auth-wrapper auth-v2 bg-gradient-to " id="page-login">
      <div class="row auth-inner m-0">
        <div class="hidden lg:block d-none d-lg-flex justify-center align-items-center p-5 col-lg-8">
          <div
            class="
              w-100
              d-lg-flex
              align-items-center
              justify-content-center
              px-5
            "
          >
            <img
              src="@/assets/images/pages/login/tela-login-02.png"
              alt="Login Duotalk"
              class="img-fluid"
            />
          </div>
        </div>
        <div class="d-flex align-items-center auth-bg px-2 p-lg-5 col-lg-4">
          <div class="brand-logo w-full">
         <div class="vx-logo cursor-pointer flex items-center router-link-active"><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 114.88 121" xml:space="preserve" class="w-12 mr-2 fill-current text-primary"><g><path d="M29.45,33.15l-2.9,33.15c-0.4,4.58,2.99,8.29,7.56,8.29h66.3c4.58,0,8.61-3.71,9.01-8.29l5.44-62.16
		c0.2-2.29-1.49-4.14-3.78-4.14c-1.67,0-3.19,0.98-3.97,2.4L96.38,22.41c-0.79,1.39-2.35,2.45-4,2.45H38.47
		C33.89,24.86,29.85,28.57,29.45,33.15z" class="st0"></path><path d="M85.43,87.85l2.9-33.15c0.4-4.58-2.99-8.29-7.56-8.29h-66.3c-4.58,0-8.61,3.71-9.01,8.29l-5.44,62.16
		c-0.2,2.29,1.49,4.14,3.78,4.14c1.67,0,3.19-0.98,3.97-2.4l10.74-20.01c0.79-1.39,2.35-2.45,4-2.45h53.92
		C80.99,96.14,85.03,92.43,85.43,87.85z" class="st1"></path><path d="M88.33,54.7c0.4-4.58-2.99-8.29-7.56-8.29H28.29L26.55,66.3c-0.4,4.58,2.99,8.29,7.56,8.29h52.48L88.33,54.7z" class="st2"></path></g></svg>
    <span class="vx-logo-text  text-gray-900"> duo<b>talk</b></span></div>
        </div>
          <div class="px-xl-2 mx-auto col-sm-8 col-md-6 col-lg-12">
            <h2 class="inline-block py-1 px-2 mb-2 rounded bg-indigo-50 text-indigo-500 text-xs font-medium uppercase tracking-widest">Login</h2>
            <p class="card-text mb-4 text-3xl text-gray-900">{{ $t("LoginWelcomeBack") }} 👋</p>
            <login-jwt></login-jwt>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>


import LoginJwt from "./LoginJWT.vue";

export default {
  components: {
    LoginJwt,
  },
};
</script>

<style lang="scss">
@font-face {
  font-family: "Merienda";
  src: local("Merienda"),
   url(https://cdn.jsdelivr.net/npm/@openfonts/maven-pro_latin@1.44.0/index.min.css) format("truetype");
}

.vx-logo-text { 
  font-family: 'Maven Pro', sans-serif;
  font-size: 3rem;
}
        .st0 {
            fill: #2658c6
        }

        .st1 {
            fill: #002f8e
        }

        .st2 {
            fill: #00193c
        }

.bg-gradient-to {
background: rgb(38,88,198);
background: linear-gradient(145deg, rgba(38,88,198,1) 25%, rgba(9,9,121,1) 50%, rgba(0,47,142,1) 100%);
  }
.tracking-widest {
    letter-spacing: .1em;
}
 
.text-indigo-500 {
    --tw-text-opacity: 1;
    color: rgba(99,102,241,var(--tw-text-opacity));
}
.bg-indigo-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(238,242,255,var(--tw-bg-opacity));
}
.auth-wrapper.auth-v2 {
  -webkit-box-align: start;
  align-items: flex-start;
}
.auth-wrapper {
  display: flex;
  flex-basis: 100%;
  min-height: calc(var(--vh, 1vh) * 100);
  width: 100%;
}
.auth-wrapper.auth-v2 .auth-inner {
  height: calc(var(--vh, 1vh) * 100);
}
.auth-wrapper .auth-inner {
  width: 100%;
  position: relative;
}
.auth-wrapper .auth-bg {
  background-color: #fff;
}
[dir="ltr"] .ml-auto,
[dir="ltr"] .mx-auto {
  margin-left: auto !important;
}
[dir="ltr"] .mr-auto,
[dir="ltr"] .mx-auto {
  margin-right: auto !important;
}
.d-none {
  display: none !important;
}

[dir="ltr"] .pl-2,
[dir="ltr"] .px-2 {
  padding-left: 1.5rem !important;
}
[dir="ltr"] .pr-2,
[dir="ltr"] .px-2 {
  padding-right: 1.5rem !important;
}
.row {
  display: flex;
  flex-wrap: wrap;
}
*,
:after,
:before {
  box-sizing: border-box;
}
[dir="ltr"] .auth-wrapper.auth-v2 .brand-logo {
  left: 4rem;
}
[dir] .auth-wrapper.auth-v2 .brand-logo {
  margin: 0;
}
.auth-wrapper.auth-v2 .brand-logo {
  position: absolute;
  top: 2rem;
  z-index: 1;
}
.auth-wrapper .brand-logo {
  display: flex;
  -webkit-box-pack: left;
  justify-content: left;
}
.img-fluid,
.img-thumbnail {
  max-width: 100%;
  height: auto;
}
img {
  vertical-align: middle;
}
.align-items-center {
  -webkit-box-align: center !important;
  align-items: center !important;
}
@media (min-width: 992px) {
  .d-lg-flex {
    display: flex !important;
  }
  .col-lg-8 {
    -webkit-box-flex: 0;
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-lg-4 {
    -webkit-box-flex: 0;
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .p-lg-5 {
    padding: 4rem !important;
  }
}
@media (max-width: 991px) {
  .sm-block {
    display: block !important;
  }
  .brand-logo {
    left: 0 !important;
    position: unset;
    text-align: center !important;
    justify-content: center !important;
  }
}
.col,
.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-auto {
  position: relative;
  width: 100%;
}
[dir] .p-5 {
  padding: 4rem !important;
}
.d-flex {
  display: -webkit-box !important;
  display: flex !important;
}
</style>
